.store-links {
    max-width: 640px;
    margin: auto;
    
    a {
        height: auto;
        width: 40%;
        margin-right: 10%;
        margin-left: 10%;
        filter: drop-shadow(0 0 7px grey);
    }
}