.doctor-media-carousel {
  position: relative;
  
  .navigation-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: #6E1EAB;
    border-color: transparent;
    box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02);
    
    .icon {
      color: white;
    }
    
    &:hover {
      border-color: transparent;
    }

    &:focus {
      border-color: transparent;
    }
  }

  .next {
    right: -10px;
  }

  .prev {
    left: -10px;
  }
  
  .innerSlide {
    cursor: pointer;
  }
  
  figure {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}