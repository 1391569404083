.faq-entry {
    .card-header {
        border-top: solid 1px #6E1EAB;
        text-align: left;

        &:has(+ .card-content) {
            //border-bottom: solid 1px #6E1EAB;
        }
    }
    
    // no top border for the first card-header
    &:first-child {
        .card-header {
            border-top: none !important;
        }
    }
    
    // no top border radius for second onward card-header
    &:not(:first-child) {
        .card-header {
            border-top-right-radius: 0;
            border-top-left-radius: 0;
        }
    }

    .card-header-title, .card-header-icon {
        color: #6E1EAB !important;
    }

    
    .card-content {
        text-align: left;
        color: #6E1EAB;
    }
}