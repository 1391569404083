.campaign-code {
    p {
        color: #6E1EAB;
    }
    
    .progress {
        width: 283.5px;
        &:indeterminate {
            background-image: linear-gradient(to right, rgba($color: #6E1EAB, $alpha: 1.0) 30%, #ededed 30%);
        }
    }
    
    .code-container {
        background-color: white;
        padding: 10px;
        border-radius: 50px;
        min-height: 64px;
    }
    
    .logo {
        width: 200px;
        background: transparent;
    }
    
    .copy-to-clipboard-button {
        border-radius: 50px;
        background-color: #6E1EAB;
        margin-left: 0.5rem;
        
        &.hidden {
            display: none !important;
            margin-left: 0 !important;
        }
    }
}