.image-with-preview {
    .is-zoomable {
        cursor: zoom-in;
    }

    .image img {
        width: auto;
    }

    .modal-content {
        max-height: calc(100vh - 100px);
    }

    .modal-content .image img {
        height: calc(100vh - 100px);
        width: auto;
    }
}