.topBar {
    font-size: 24px;
    color: #6E1EAB;
    background-color: transparent !important;
    padding-top: 30px;

    .navbar-item img {
        max-height: 5rem;
    }

    .navbar-burger {
        color: #6E1EAB;
    }

    @media screen and (max-width: 1023px) {
        .navbar-menu {
            background-color: transparent !important;
            box-shadow: 0 8px 16px -16px rgb(10 10 10 / 10%);
        }
    }
}

.topBarLogo {
    height: 70px;
}

.topBarLinks {
    font-weight: 500;
}

.topBarLink {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    position: relative;
    overflow: hidden;
    margin: 0 20px;
    padding: 5px;
    cursor: pointer;
    color: #6E1EAB;
}

.topBarLink:hover {
    color: #6E1EAB !important;
}

.topBarLink:before {
    content: "";
    position: absolute;
    z-index: -1;
    left: 51%;
    right: 51%;
    bottom: 0;
    background: #6E1EAB;
    height: 4px;
    -webkit-transition-property: left, right;
    transition-property: left, right;
    -webkit-transition-duration: 0.25s;
    transition-duration: 0.25s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
}

.topBarLink:hover:before, .topBarLink:focus:before, .topBarLink:active:before {
    left: 0;
    right: 0;
}

.scroll-to-top {
    border: 1px solid white;
    padding: 10px;
    border-radius: 20px 0 0 20px;
    position: fixed;
    background-color: #6E1EAB;
    cursor: pointer;
    right: 0;
    bottom: 100px;
    box-shadow: 0 0 10px 0 rgb(10 10 10 / 20%);
    z-index: 1000;
}