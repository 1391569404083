.home-info-row {
    //height: 600px;
    color: #6E1EAB;

    .box-label {
        text-transform: uppercase;
        border-bottom: 4px solid #6E1EAB;
        display: inline-block;
        font-weight: 650;
        font-size: 24px;
        margin: 0 0 10px 0;
        padding: 5px 0;

        @media (max-width: 480px) {
            font-size: 22px;
        }

        @media (max-width: 1024px) {
            font-size: 20px;
        }
    }

    .box-point {
        margin: 0 0 40px 0;
        font-size: 64px;
        font-weight: 700;

        @media (max-width: 480px) {
            font-size: 32px;
        }

        @media (max-width: 1024px) {
            font-size: 48px;
        }
    }

    .box-desc {
        font-size: 17px;
        font-weight: 500;
    }

    .box-app-store-links {
        margin-top: 50px;
    }

    .box-tests-links {
        margin-top: 50px;
    }

    .box-test-link {
        width: 40%;
        margin-right: 10%;
        background-color: #6E1EAB;
        border-radius: 20px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;

        font-size: 20px;
        font-weight: 700;
        color: white;
        text-align: center;

        box-shadow: 0 0 5px 0 grey;
    }

    .emailLink {
        font-size: 20px;
    }
}