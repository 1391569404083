@charset "utf-8";

@import url("https://pro.fontawesome.com/releases/v5.10.0/css/all.css"); //If you want font awesome

@import "~bulma/sass/utilities/functions";

// Custom theme colors
$primary: #6c0daf; // this variable name already exists in bulma so no need to create the inverted ligth and dark versions of it, nor add it to the custom colors variable
$secondary: hsl(171, 100%, 31%) !default;

$secondary-invert: findColorInvert($secondary);
$secondary-light: findLightColor($secondary);
$secondary-dark: findDarkColor($secondary);

$custom-colors: (
    "secondary": (
        // order matters
        $secondary,
        $secondary-invert,
        $secondary-light,
        $secondary-dark,
    ),
);

// Colors have to be imported after you add your color changes.
@import "~bulma/sass/utilities/initial-variables";
@import "~bulma/sass/utilities/derived-variables";
// From here, the colors can be used however you want.

$input-focus-border-color: $primary;
$input-focus-box-shadow-size: 0;
$menu-item-active-background-color: $primary;
$navbar-dropdown-border-top: 2px solid $primary; // this guy: https://prnt.sc/1l11g4n, if you want to change the color of that border, I prefer it on $primary rather than the default which is grey.
$pagination-current-background-color: $primary; //https://prnt.sc/1l12oxn this guy

$shadow: 0 0.25em 1em 0em rgba($scheme-invert, 0.1),
0 0px 0 1px rgba($scheme-invert, 0.02);

// This should be always at the end
@import "~bulma/bulma";

// add here anything that is shared in the app
.has-styled-lists ul {
    list-style-type: circle;
    list-style-position: inside;
    padding-left: 20px;
}

.button.is-circular {
    border-radius: 50% !important;
}

.button {
    font-family: "Lexend", sans-serif;
}

.field {
    text-align: left;

    .label {
        color: #6E1EAB;
        font-size: 14px;
    }
}