.change-display-language {
    padding: 10px;
    border-radius: 20px 0 0 20px;
    position: fixed;
    background-color: #6E1EAB;
    cursor: pointer;
    right: 0;
    top: 120px;
    box-shadow: 0 0 10px 0 rgb(10 10 10 / 20%);
    z-index: 40;
}

// used to handle click outside
.change-display-language-background {
    z-index: 35;
    background: transparent;
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
}