@import "styles/Common";

.join-us-page {
    color: $primary !important;

    .page-title {
        margin: 0 0 40px 0;
        font-size: 64px;
        font-weight: 700;

        @media (max-width: 480px) {
            font-size: 32px;
        }

        @media (max-width: 1024px) {
            font-size: 48px;
        }
    }

    .text-description {
        font-size: 17px;
        font-weight: 500;
    }
}