.footer {
    background-color: #6E1EAB !important;
    color: #ffffff;

    strong {
        color: #ffffff;
    }

    a {
        color: #009E86FF;
    }

    .links a {
        padding: 0 10px;
    }
}