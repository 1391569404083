//@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100;200;300;400;500;600;700;800;900&display=swap');

.app {
    font-family: "Lexend", sans-serif;
    line-height: 1.2;

    text-align: center;
    background-size: cover;
    min-height: 100vh;
    max-width: 100vw;
    display: flex;
    flex-direction: column;
}

.app::before {
    content: "";
    background-image: url("assets/agatha-gradient.svg");
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    position: fixed;
    display: block;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0.1;
    z-index: -1;
}