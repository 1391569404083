.doctor {
    .profile-picture {
        border: #6E1EAB 4px solid;
        max-width: 200px;
        margin: auto;
    }

    .title {
        padding: 0;
        margin: 0;
        color: #6E1EAB;
    }

    .score {
        color: #6E1EAB;
    }

    .call-now-button {
        //text
        color: #6E1EAB;
        font-weight: bold;

        // button
        background-color: transparent;
        border: #6E1EAB solid 1px;
        border-radius: 8px;
    }

    .at-a-glance, .career-history {
        .icon-text {
            flex-wrap: nowrap;

            .icon {
                color: #6E1EAB;
                margin-right: 10px;
            }
        }
    }

    .quote {
        svg {
            color: #6E1EAB;
        }

        font-size: 24px;
    }
}